<template lang="pug">
  
  .home
   
    .row.mt-1
      .col-md-3

        b-nav(vertical).p-0.m-0
            //b-nav-item(to="admin#reset") Custom Fields
            //b-nav-item(to="admin#backup").p-0 Backup
            b-nav-item(to="admin#reset") Reset
        
      .col-md-9()

         
            //b-card(title="Custom Fields")
            
            // b-card(title="Backup & Report").mt-5
                b-btn(size="sm") Backup and Export
            
            b-card(title="Reset")
                p.small.text-muted Caution: All data will be deleted
                b-btn(variant="danger", size="sm", @click="reset()", id="reset-btn") Reset instance
                .small.mt-1(v-if="reset_done",id="reset-done") Reset done
            
       
</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
import NewsFilter from '@/views/news/NewsFilter'
import OrgView from '@/components/OrgView.vue'


import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'home',
  
  data: function () {
    return {
        reset_done: false
    }
  },

  mounted: function() {
 
    this.host = Config.config.base_host;
    

  },

   computed: {

        ...mapFields([
           
        ]),
  },

  methods: {

    reset() {

        this.$bvModal.msgBoxConfirm("Are you sure? All data will be deleted", {buttonSize: 'sm'}).then((value) => {
            console.log(value);

            if(value) {
                this.$store.dispatch("start_loading");
                Vue.http.post(`${Config.config.base_host}/admin/reset`).then(res => {
                    console.log(res);
                    this.reset_done = true;
                    this.$store.dispatch("stop_loading");
                });

            }
            
      
        });
    }

  },
  components: {
    OrgView,
    NewsFilter
  }
}

</script>
<style lang="scss" scoped>


</style>