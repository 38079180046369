<template lang="pug">

  .home   
    b-overlay(:show="$store.state.loading")
      .row.mt-1(v-if="configurations")
        
        .d-none.d-md-block.col-md-2
          b-list-group()
            
            b-list-group-item(href="#project_organization", v-for="conf in configurations") {{conf.name}}
        
            //b-list-group-item(href="#evaluation") Evaluations

            //b-list-group-item(href="#api") Custom API Connect
        
        .col-0.col-md-1
        .col-12.col-md-8(v-if="configurations")
           
            
                b-card(v-for="field in configurations", @click="active = field", :class="{'shadow': active == field}").mt-1
                    a.text-bold(href="javascript:void(0);") {{field.name}}
                   
                    template(v-if="active == field")
                        hr
                        b-form
                        .form-row
                            .col-6
                                b-form-group(label="Name")
                                    b-input(v-model="active.name", size="sm")
                            .col-6
                                b-form-group(label="Context")
                                    b-select(v-model="active.context", :options="['Organization', 'ProjectOrganization', 'Contact']" size="sm", required)
                            

                        .form-row
                            .col-12
                                b-form-group(label="API URL")
                                    b-input(v-model="active.url", size="sm").mt-1.small
                            
                        .form-row
                            .col-6
                                b-form-group(label="Required fields")
                                    v-select(size="sm", v-model="active.required_fields", :multiple="true"
                              :taggable="true"
                              :close-on-select="false"
                              :no-drop="true"
                              :select-on-tab="true").small
                            //.col-6
                                b-form-group(label=" ").mt-4
                                    b-check(v-model="active.required", size="sm") This field is required

                        .form-row
                            .col-12
                                b-form-group(label="View Template")
                                    b-textarea(size="sm", v-model="active.custom_template", :rows="10")
                        .form-row
                            .col-12
                                //b-btn(size="sm", variant="link", @click="remove(field)") Delete this field
                                .float-right
                                    b-btn(size="sm", variant="primary", @click="save(active)") Save
                               


                .mt-2
                    
                    b-btn(size="sm", variant="outline-secondary", @click="addField()") 
                        i.fas.fas-plus
                        | + Create API Connect
                    
           
         

</template>

<script>
// @ is an alias to /src

import Vue from 'vue';
import Config from '@/config'


import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Analytics',
  
  data: function () {
    return {
      configurations: [],
    
      active: null
     
    }
  },

  mounted: function() {
    this.host = Config.config.base_host;
    this.getData();
  },

   computed: {
        ...mapFields([
            'query.keywords'
        ]),
  },

   watch: {
    
   
  },


  methods: {

    addField() {
        let newField = {"data_type": "text", "label": "New Field"}
        this.configurations.push(newField);
        this.active = newField;
    },
    
    cleanup(text) {
     return text.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
    },

    checkName(active) {
        if(!active.name) active.name  = this.cleanup(active.label);
    },

    save(conf) {
        this.$store.dispatch('start_loading');
        Vue.http.post(`${Config.config.base_host}/api_configurations/${conf._id}`, conf).then(() => {
            this.getData();
            
        });
    },

    getData() {
      this.$store.dispatch('start_loading');

      Vue.http.get(`${Config.config.base_host}/api_configurations`).then(res => {
            this.configurations = res.body;
   
            this.$store.dispatch('stop_loading');
        });
    },

  },

  components: {

  }

}

</script>
<style lang="scss" scoped>

 #gcontainer {
        height: 500px;
        width: 100%;
    }

.feed-image {
    height: 50px;
    width: 50px;
}
.table td {
    padding: 0.2em;
}
.item {
    .links {
        //display: none;
        a{
       
        }
    }
   
}
</style>