import { render, staticRenderFns } from "./Reporting.vue?vue&type=template&id=30b87551&scoped=true&lang=pug"
import script from "./Reporting.vue?vue&type=script&lang=js"
export * from "./Reporting.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30b87551",
  null
  
)

export default component.exports