<template lang="pug">
  .user-modal
    b-modal(id="role-modal", :title="title", size="md", ok-variant="outline-primary", cancel-variant="link", ref="user-ref",  @ok="save()")
      b-form(size="sm", autocomplete="off")
        .form-row()
         

          .col-12
            b-form-group(id="name-group"  
                        label="Role Name"
                        size="sm"
                        label-for="name"
                        description="",
                        v-bind:class="{ 'form-group--error': $v.form.name.$error }")

                    b-form-input(id="name"
                                type="text"
                                :state="!submitStatus ? null : !$v.form.name.$invalid"
                                size="sm"
                                placeholder="The name of the role, e.g. StandardUser", v-model="form.name")
       

        
          
           
      template(slot="modal-footer")
            //b-btn(variant="outline-secondary" @click="$refs.modal1.hide(false)") Close
            b-btn(variant="primary"  @click="save()")#user-modal-save {{ok_btn_text}}



</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'

import { required, minLength } from 'vuelidate/lib/validators'
import CountrySelect from '@/components/CountrySelect'
import UserMultiSelect from '@/components/UserMultiSelect'
import TopicKeywordSelect from '@/components/TopicKeywordSelect'
import Config from '@/config'
import Vue from 'vue'

export default {

  name: 'CompanyModal',
  props: [],
  
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(1)
        }
     
    }
  },

  data: function () {
    return {
        
        uid: null,
        title: "Add a new role",
        form: {"name": "", "permissions": [], "active": true, "send_confirmation": true},
        tole: {},
        options: [],
        submitStatus: null,
        ok_btn_text: "Add Role"
        
    }
  },

  mounted: function() {
     this.uid = this._uid;
  },

  methods: {

    open(role) {
      
      this.title = "Add a new role";
     

      if(role) {
        this.form = JSON.parse(JSON.stringify(role));
        this.role = role;
        this.ok_btn_text = "Save";
        this.title = "Edit " + role.name;
        
      } else {
        
        this.form = {"name": "", "permissions": [], "active": true, "send_confirmation": true};
        this.role =  {"name": "", "permissions": [], "active": true, "send_confirmation": true};
      
      }

      
      this.$refs['user-ref'].show()
    },
    
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'
        console.log(this.form);
        Vue.http.post(`${Config.config.base_host}/admin/roles`, this.form).then((res) => {
          console.log(res);
          location.href="/settings/roles";
        }, (res) => {
          this.$bvModal.msgBoxOk("Error: " +  res.body.detail);
        });
        
      }
      

    },

    
  },

  components: {
    CountrySelect,
    UserMultiSelect,
    TopicKeywordSelect
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
