<template lang="pug">

  .home   
    b-overlay(:show="$store.state.loading")
      .row.mt-1
        
        .d-none.d-md-block.col-md-2
          
        .col-0.col-md-1
        .col-12.col-md-8(v-if="configuration")

            //label.mt-0#setup Welcome Widget shown on Home Screen
            //h-editor()


            label.mt-0#setup Instance Setup
            b-card()
                        
                .mt-0

                    b-form-group(id="stages-group"
                                label="Organization Types"
                                label-for="exampleInput1"
                                description="").mt-2

                        v-select(id="company_types"
                                        :options="[]",
                                        :multiple="true"
                                        :taggable="true"
                                        :close-on-select="false"
                                        :no-drop="true"
                                        placeholder="Enter a type name and press enter"
                                        :select-on-tab="true"
                                        v-model="configuration.organization_types")
                        

                    b-form-group(id="company_types-group"
                                label="Default Funnel/ Board Stages"
                                label-for="exampleInput1"
                                description="").mt-2

                        v-select(id="company_types"
                                        :options="[]",
                                        :multiple="true"
                                        :taggable="true"
                                        :close-on-select="false"
                                        :no-drop="true"
                                        placeholder="Enter a stage and press enter"
                                        :select-on-tab="true"
                                        v-model="configuration.default_board_stages")
                    
                    b-form-group(id="cnewsletter_sections-group"
                                label="Default Newsletter Sections"
                                label-for="exampleInput1"
                                description="").mt-2

                        v-select(id="newsletter_sections"
                                        :options="[]",
                                        :multiple="true"
                                        :taggable="true"
                                        :close-on-select="false"
                                        :no-drop="true"
                                        placeholder="Enter a section and press enter"
                                        :select-on-tab="true"
                                        v-model="configuration.default_newsletter_sections")
                    
                    b-form-group(id="trial_until-group"
                                label="Trial until"
                                label-for="exampleInput1"
                                description="", v-if="$store.state.user.email.indexOf('@hy.co') > -1").mt-2

                        b-input-group()
                           
                          
                            b-form-input(
                                id="trial_until-input"
                                v-model="configuration.trial_until"
                                type="text"
                                placeholder="YYYY-MM-DD"
                                autocomplete="off")
                           
                            b-input-group-append()
                                b-form-datepicker(
                                    v-model="configuration.trial_until"
                                    button-only
                                    right
                                    locale="en-US"
                                    aria-controls="trial_until-input"
                                    @context="onContext")
                              

                .form-row
                    .col-12
                        //b-btn(size="sm", variant="link", @click="remove(field)") Delete this field
                        .float-right
                            b-btn(size="sm", variant="primary", @click="save()") Save
                       
            .mt-5
                label#project_organization Organization Data Fields
            
                    
                b-card(v-for="field in configuration.data_fields", @click="active = field", :class="{'shadow': active == field}").mt-1
                    a.text-bold(href="javascript:void(0);") {{field.group}}: {{field.label}} &dash; 
                    .text-muted.d-inline.small {{field.data_type}} {{field.name}}
                    template(v-if="active == field")
                        hr
                        b-form
                        .form-row
                        
                            .col-6
                                b-form-group(label="Section")
                                    b-input(v-model="active.group", size="sm", required)
                            .col-6
                                b-form-group(label="Label*")
                                    b-input(v-model="active.label", size="sm", @change="checkName(active)")

                        .form-row

                            .col-6
                                b-form-group(label="Input type")
                                    b-select(:options="['select', 'text', 'textarea', 'slider', 'api_field', 'date', 'money_amount']", size="sm", v-model="active.data_type")
                                    v-select(v-if="active.data_type=='select'", placeholder="Options", :multiple="true", :taggable="true", :no-drop="true", v-model="active.options").mt-1.small
                            
                        .form-row
                            .col-6
                                b-form-group(label="Name")
                                    b-input(size="sm", v-model="active.name")
                            //.col-6
                                b-form-group(label=" ").mt-4
                                    b-check(v-model="active.required", size="sm") This field is required

                        .form-row
                            .col-12
                                b-form-group(label="Description / Help text")
                                    b-textarea(size="sm")
                        .form-row
                            .col-12
                                //b-btn(size="sm", variant="link", @click="remove(field)") Delete this field
                                .float-right
                                    b-btn(size="sm", variant="primary", @click="save()") Save
                               
                .mt-2
                    
                    b-btn(size="sm", variant="outline-secondary", @click="addField()") 
                        i.fas.fas-plus
                        | + Create data field
            .mt-5
                label#project_organization GDPR Configuration
                b-card()
                            
                        b-form-group(id="gdpr_notify-group"
                                    label="Notify after months"
                                    label-for="exampleInput1"
                                    description="Months for to review data")

                            b-input(type="number", v-model="configuration.gdpr_notify_every_month").col-4
                        .form-row
                            .col-12
                                //b-btn(size="sm", variant="link", @click="remove(field)") Delete this field
                                .float-right
                                    b-btn(size="sm", variant="primary", @click="save()") Save
                            


            //label.mt-5#integration Integration
            //b-card()
            
                .mt-0


                    b-form-group(id="stages-group"
                                label="Sections to be shown on insights tab"
                                label-for="exampleInput1"
                                description="").mt-2

                        v-select(id="company_types"
                                        :options="[]",
                                        :multiple="true"
                                        :taggable="true"
                                        :close-on-select="false"
                                        :no-drop="true"
                                        placeholder="Enter a type name and press enter"
                                        :select-on-tab="true"
                                        v-model="configuration.organization_types")
                        

                    b-form-group(id="company_types-group"
                                label="Default Funnel/ Board Stages"
                                label-for="exampleInput1"
                                description="").mt-2

                        v-select(id="company_types"
                                        :options="[]",
                                        :multiple="true"
                                        :taggable="true"
                                        :close-on-select="false"
                                        :no-drop="true"
                                        placeholder="Enter a stage and press enter"
                                        :select-on-tab="true"
                                        v-model="configuration.default_board_stages")
         

</template>

<script>
// @ is an alias to /src

import Vue from 'vue';
import Config from '@/config'
import HEditor from '@/components/HEditor.vue'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Analytics',
  
  data: function () {
    return {
      configuration: {data_fields: []},
      custom_group: null,
      active: null
     
    }
  },

  mounted: function() {
    this.host = Config.config.base_host;
    this.getData();
  },

   computed: {
        ...mapFields([
            'query.keywords'
        ]),
  },

   watch: {
    
   
  },


  methods: {
    onContext(ctx) {
        // The date formatted in the locale, or the `label-no-date-selected` string
        this.formatted = ctx.selectedFormatted
        // The following will be an empty string until a valid date is entered
        this.selected = ctx.selectedYMD
    },
    
    addField() {
        let newField = {"data_type": "text", "label": "New Field"}
        this.configuration.data_fields.push(newField);
        this.active = newField;
    },
    
    cleanup(text) {
     return text.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
    },

    checkName(active) {
        if(!active.name) active.name  = this.cleanup(active.label);
    },

    save() {
        this.$store.dispatch('start_loading');
        Vue.http.post(`${Config.config.base_host}/configuration/config:main`, this.configuration).then(res => {
            this.configuration = res.body;

            this.$store.dispatch('stop_loading');
        });
    },
    getData() {
      this.$store.dispatch('start_loading');

      Vue.http.get(`${Config.config.base_host}/configuration/config:main`).then(res => {
            this.configuration = res.body;

            if(!this.configuration.data_fields) this.configuration.data_fields = [];
           
            this.$store.dispatch('stop_loading');
        });
    },

  },

  components: {
      HEditor
  }

}

</script>
<style lang="scss" scoped>

 #gcontainer {
        height: 500px;
        width: 100%;
    }

.feed-image {
    height: 50px;
    width: 50px;
}
.table td {
    padding: 0.2em;
}
.item {
    .links {
        //display: none;
        a{
       
        }
    }
   
}
</style>