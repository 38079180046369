<template lang="pug">
  .tax-modal
    b-modal(id="tax-modal", :title="title", size="md", ok-variant="outline-primary",   no-close-on-backdrop, cancel-variant="link", ref="taxmodal",  @ok="save()")
      b-form(size="sm", autocomplete="off")
        .form-row()
         

          .col-12
            b-form-group(id="name-group"  
                        label="Topic Name"
                        size="sm"
                        label-for="name"
                        description="",
                        v-bind:class="{ 'form-group--error': $v.form.name.$error }")

                    b-form-input(id="name"
                                type="text"
                                :state="!submitStatus ? null : !$v.form.name.$invalid"
                                size="sm"
                                placeholder="The name of the topic", v-model="form.name")
       
            b-form-group(id="type-group"  
                    label="Topic type"
                    size="sm"
                    label-for="name"
                    description="",
                    v-bind:class="{ 'form-group--error': $v.form.topic_type.$error }")

                b-select(id="name"
                          type="text"
                          :state="!submitStatus ? null : !$v.form.topic_type.$invalid"
                          size="sm"
                          :options="topic_types"
                          placeholder="The type of the topic", v-model="form.topic_type")
            
            b-form-group(id="parent-group"  
                    label="Parent topics"
                    size="sm"
                    label-for="name"
                    description="",
                   )

              
                
                TaxonomySelect(id="parent"
                            size="sm"
                            :topic_type="''"
                            :show_type="true"
                            placeholder="parents", v-model="form.parent_ids")
        
          
           
      template(slot="modal-footer")
            b-btn(variant="outline-secondary" @click="$refs.taxmodal.hide(false)") Close
            b-btn(variant="primary"  @click="save()")#user-modal-save {{ok_btn_text}}



</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'

import { required, minLength } from 'vuelidate/lib/validators'
import CountrySelect from '@/components/CountrySelect'
import UserMultiSelect from '@/components/UserMultiSelect'
import TopicKeywordSelect from '@/components/TopicKeywordSelect'
import TaxonomySelect from '@/components/TaxonomySelect'
import Config from '@/config'
import Vue from 'vue'

export default {

  name: 'CompanyModal',
  props: [],
  
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(1)
        },
        topic_type: {
            required,
            minLength: minLength(1)
        }
     
    }
  },

  data: function () {
    return {
        
        uid: null,
        title: "Add a new topic",
        form: {"name": "", "permissions": [], "active": true, "send_confirmation": true},
        tole: {},
        topic_types: null,
        options: [],
        submitStatus: null,
        ok_btn_text: "Add topic"
    }
  },

  mounted: function() {
     this.uid = this._uid;
  },

  methods: {

    open(role, topic_types) {
      
      this.title = "Add a new topic";
      this.topic_types = topic_types;

      if(role) {
        this.form = JSON.parse(JSON.stringify(role));
        this.role = role;
        this.ok_btn_text = "Save";
        this.title = "Edit " + role.name;
      } else {
        this.form = {"name": "", "permissions": [], "active": true, "send_confirmation": true};
        this.role =  {"name": "", "permissions": [], "active": true, "send_confirmation": true};
      }

      
      this.$refs['taxmodal'].show()
    },
    
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'
        console.log(this.form);
        
        if(!this.form._id) {
          Vue.http.post(`${Config.config.base_host}/topics_v1`, this.form).then((res) => {
            console.log(res);
            location.href="/settings/taxonomy";
          }, (res) => {
            this.$bvModal.msgBoxOk("Error: " +  res.body.detail);
          });
        } else {
          Vue.http.put(`${Config.config.base_host}/topics_v1/${this.form._id}`, this.form).then((res) => {
            console.log(res);
            location.href="/settings/taxonomy";
          }, (res) => {
            this.$bvModal.msgBoxOk("Error: " +  res.body.detail);
          });
        }
        
        
      }
      

    },

    
  },

  components: {
    CountrySelect,
    UserMultiSelect,
    TopicKeywordSelect,
    TaxonomySelect
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
