<template lang="pug">
  .onboard-modal
    b-modal(id="onboardmodal", :title="title", size="xl", ok-variant="outline-primary", cancel-variant="link", ref="onboardmodal",  @ok="save()")


        b-form(size="sm", autocomplete="off", v-if="step==1", style="min-height: 500px")

          .form-row()
            .col-12
              .row.mt-2()
                .col-12
                  label Select a category

              b-row(cols="1" cols-sm="1" cols-md="2" cols-lg="6", v-if="industries").form-row
                b-col(v-for="topic in industries.rows")
                  .topic-badge( v-bind:class="{'active': (selectedIndustry && selectedIndustry._id == topic._id)}", @click="getTopics(topic)", style="cursor: pointer").mt-2.align-middle 
                    .inside {{topic.name}}

          .row.mt-3(v-if="topics && topics.length > 0", style="max-height: 400px; overflow-y: scroll")
            .col-12
              label.mt-4 Topics
              .text-muted Click on a topic to subscribe/unsubscribe.
              b-overlay(:show="$store.state.loading").w-100
                b-row(cols="1" cols-sm="1" cols-md="2" cols-lg="5").mt-2.form-row
                
                  div(v-if="topics && topics.length == 0").text-muted.mt-3 There are no topics in this category.
                  
                  b-col(v-for="topic in $_.orderBy(topics, ['name'], ['asc'])", v-if="user").d-flex.align-items-stretch.mb-2.w-100
                        
                          b-card(no-body, v-bind:class="{'active': user.pins.map(x => x.item_id).indexOf(topic._id) > -1}", style="min-height: 4rem").w-100.h-100.shadow-none.t
                          
                                  h-pinuser(:item_id="topic._id", item_type="Topic", variant="none", :user="user").h-100.w-100
                                     | {{topic.name}}
                                     template(#unfollow)
                                      | {{topic.name}}
                                      .small Unfollow
                                     template(slot="following")
                                      | {{topic.name}}
                                      .small Following

                    

       
        template(slot="modal-footer")
              div.mr-auto
                
              //b-btn(variant="outline-secondary" @click="saveOnboarded(); $refs.onboardmodal.hide(false)") Skip

              b-btn(variant="primary"  @click="$refs.onboardmodal.hide(false)", )#user-modal-save Close



</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'

import { required, minLength } from 'vuelidate/lib/validators'
import CountrySelect from '@/components/CountrySelect'
import UserMultiSelect from '@/components/UserMultiSelect'
import TopicKeywordSelect from '@/components/TopicKeywordSelect'
import Config from '@/config'
import Vue from 'vue'

export default {

  name: 'OnboardModal',
  props: [],
 
  
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(1)
        }
     
    }
  },

  data: function () {
    return {
        user: null,
        loading: true,
        uid: null,
        title: "Topics",
        form: {"name": "", "permissions": [], "active": true, "send_confirmation": true},
        tole: {},
        industries: [],
        trend: {},
        step: 1,
        topics: [],
        options: [],
        selectedIndustry: null,
        submitStatus: null,
        ok_btn_text: "Add Role"
        
    }
  },

  mounted: function() {
     this.uid = this._uid;
     this.getIndustries();
  },

  methods: {

   

     getTrends(topic_id) {
        this.$store.dispatch("start_loading");
         Vue.http.get(Config.config.base_host+'/topics_v1/media_attention/'+topic_id).then(resp => {
            this.trends[topic_id] = resp.body;
            this.$forceUpdate();
            this.$store.dispatch("stop_loading");
        }); 
      },
      getIndustries() {
        
        Vue.http.post(Config.config.base_host+'/topics_v1/search', {"topic_type": "industry"}).then(resp => {
         
          this.industries = resp.body;
          this.industries.rows = this.$_.sortBy(this.industries.rows, ["name"])

          this.getTopics(this.industries.rows[0]); 
          
        }); 

      },

      getTopics(industry) {
        this.selectedIndustry = industry;
        
        let params = {};
        
        if(this.project) {
          params = {"topic_type": "topic", "project_id": [this.project._id]};
        } else {
          params =  {"topic_type": "topic", "parent_id": [industry._id]};
        
        }

        params['limit'] = 100;
        
        Vue.http.post(Config.config.base_host+'/topics_v1/search', params).then(resp => {
         
          this.topics = resp.body.rows;
          this.$store.state.loading = false;
        }); 
      },

       getFollowTopic() {
      
        this.selectedIndustry = null;
       
        let params = {"topic_type": "topic", "bookmarked": true};
       
        Vue.http.post(Config.config.base_host+'/topics_v1/search', params).then(resp => {
         
          this.topics = resp.body.rows;
        }); 
      },


    open(user) {
      
      this.title = "Welcome to the Ecosystem Manager";
     

      if(user) {
        this.user = user;
        this.title = "Topics for " + this.user.email;
       
        
      } else {
        
        this.form = {"name": "", "permissions": [], "active": true, "send_confirmation": true};
       
      
      }

      setTimeout(() => {
        this.loading = false;
      }, 500);

      
      this.$refs['onboardmodal'].show()
    },
    
    save() {
     
      

    },

    
  },

  components: {
    CountrySelect,
    UserMultiSelect,
    TopicKeywordSelect
  }
}

</script>
<style lang="scss" scoped>
@import '@/_vars.scss';

.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}

.t.active {
  background-color: #29B3D2;
 

 
}
</style>
