<template lang="pug">
  
  .home
    TopicUserModal(ref="topicModal")
    UserModal(ref="userModal")
    APIKeyModal(ref="apiModal")
    .row
      .col-12
        .float-right
          b-btn(@click="showModal()", size="sm") New user
    b-row(cols="1" cols-sm="1" cols-md="2" cols-lg="2", cols-xl="3").form-row.mt-4
      b-col(v-for="user in users").mb-2
        b-card(:id="'card-'+user._id")
          .media
            User(:user="user").mr-2
            .media-body
              .float-right
                b-btn(@click="showModal(user)", size="sm", variant="outline-secondary")#edit-btn.mt-1 Edit
                b-btn(@click="showAPIModal(user)", size="sm", variant="outline-secondary",v-if="auth.has_access('api_key', null, 'read')").ml-1#api-btn.mt-1 API Keys
                b-btn(@click="showModalTopics(user)", size="sm", variant="outline-secondary")#edit-btn.mt-1.ml-1 Topics
              
              div {{user.full_name}} 
                
              .small.text-muted.mt-1 {{user.email}}
              .badge.badge-primary(v-if="!user.deleted_at") Active
              .badge.badge-secondary(v-if="user.deleted_at") Not active
              
              .badge.badge-primary(v-if="user.role_id && user.relations.role_id").ml-2 Role: {{user.relations.role_id.name}}
             
        

</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
import APIKeyModal from '@/components/APIKeyModal.vue'

import UserModal from '@/components/UserModal.vue'
import User from '@/components/User.vue'
import TopicUserModal from '@/components/TopicUserModal'

import { mapFields } from 'vuex-map-fields';
import auth from '@/router/auth.js'

export default {
  
  name: 'home',
  
  data: function () {
    return {
        reset_done: false,
        search: "",
        roles: [],
        users: [],
        auth: auth
    }
  },

  mounted: function() {
  
    Vue.http.get(`${Config.config.base_host}/users`).then((res) => {
        this.users = res.body;
    });

  },

  computed: {

       
        ...mapFields([
          
        ]),
  },

  methods: {
    
    showAPIModal(user) {
        this.$refs.apiModal.open(user);
 
    },
    showModal(user) {
        
        this.$refs.userModal.open(user);
    },

    showModalTopics(user) {
      this.$refs.topicModal.open(user);
    },

  },
  components: {
    User,
    UserModal,
    APIKeyModal,
    TopicUserModal,

  }
}

</script>
<style lang="scss" scoped>


</style>