<template lang="pug">
.main
    .sticky-top
        .loader
            .bar(v-if="$store.getters.loading")
        .submenu
            .container-xxl

                .row.no-gutters.submenu-inner
                    .col-7
                        .headline-submenu Users and Settings
                    
                .row.no-gutters.submenu-inner
                
                    .col-md-12    
                        .menu
                            //router-link(to="reporting").mr-5 Reporting             
                            router-link(to="users").mr-5 Users
                            router-link(to="roles").mr-5 Roles
                            //router-link(to="sources").mr-5 News sources
                            router-link(to="datafields").mr-5 Data Fields
                            router-link(to="taxonomy").mr-5 Taxonomy
                            router-link(to="surveys").mr-5 System Surveys
                            router-link(to="connect").mr-5 API Connect
                            router-link(to="admin").mr-5 Admin   
    .container-xxl
        .row.no-gutters
            .col-12
                router-view(name="main").main-view
            
</template>

<script>
export default {
  name: 'SubmenuEcosystem',
  props: {
    title: String,
    
  },

  data: function () {
      return {
          selectAll: false
      }
  },

  mounted: function() {
     
  },

  methods: {


  },
  computed: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    
    @import '@/_vars.scss';
 

</style>
