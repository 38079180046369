<template lang="pug">
  .home

    .row
      .col-12
        b-btn(@click="signIn()") Login
      .col-8
        //.mt-3(v-if="$store.getters.project.settings" v-for="(item, index) in $store.getters.project.settings")
            | {{item}}

</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'

export default {
  
  name: 'Settings',

  mounted: function() {
     console.log(Config);
     this.host = Config.config.base_host;
     //this.getData();
  },

  methods: {

    signIn() {
        window.auth2.grantOfflineAccess().then(this.signInCallback);
    },

    signInCallback(authResult) {
        if (authResult['code']) {  
            this.confirmOAuth(authResult['code']).then(() => {
                location.href = "/";
            });
        }
    },

    confirmOAuth(data) {
      
      let headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Google-OAuth2-Type': 'server'
      };
      this.$store.commit("LOADING", true);
      return Vue.http.post(this.host+'/swap_token', data, {headers}).then((resp) => {
          localStorage.setItem('id_token', resp.data.access_token)
          Vue.http.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('id_token');   
          this.$store.commit("LOADING", false);
          return resp.data;
      });

    },


    getData() {
        console.log(this.$store)
        this.$store.dispatch("getData");
    },


  },
  components: {
    
  }
}

</script>
<style lang="scss" scoped>
.feed-image {
    height: 50px;
    width: 50px;
}
.table td {
    padding: 0.2em;
}
.item {
    .links {
        //display: none;
        a{
       
        }
    }
   
}
</style>