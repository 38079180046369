<template lang="pug">
  
  .reporting
   
    .row.mt-1
      .col-3
        b-select(:options="kpi_options", v-model="form.kpi")
      .col-4
       
          b-input-group(id="input-group-last_funding_on")
            b-form-datepicker(v-model="form.start_date"  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }", placeholder="From").mr-3
            div(style="margin-top: 5px") &nbsp; &dash; &nbsp; 
            b-form-datepicker(v-model="form.end_date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }", placeholder="To").mr-3
      
      .col-4
        b-btn(@click="getData(form)") Show
      
      .col-12.mt-4
        b-table(striped hover :items="results")
            
       
</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
import NewsFilter from '@/views/news/NewsFilter'
import OrgView from '@/components/OrgView.vue'


import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'home',
  
  data: function () {
    return {
        results: null,
        kpi_options: ["Created: Comment", "Created: User", "Created: Topic", "Created: APIKey", "Created: Organization", "Action: user_login"],
        form: {"kpi": "Created: User", start_date: null, end_date: null}
    }
  },

  mounted: function() {
 
    this.host = Config.config.base_host;
    var x = new Date();
    x.setDate(1);
    x.setMonth(x.getMonth()-1);

    this.form.start_date = x;
    this.form.end_date = (new Date());

    
  },

   computed: {

        ...mapFields([
           
        ]),
  },

  methods: {

    getData(req) {

      this.$store.dispatch("start_loading");
      let new_req = this.$_.cloneDeep(req);

      new_req.start_date = new_req.start_date.toISOString().substring(0,10);
      new_req.end_date = new_req.end_date.toISOString().substring(0,10);

      Vue.http.post(`${Config.config.base_host}/admin/reporting`, new_req).then(res => {
          this.results = res.body;
          this.$store.dispatch("stop_loading");
      });

    }

  },
  components: {
    OrgView,
    NewsFilter
  }
}

</script>
<style lang="scss" scoped>


</style>