<template lang="pug">
  
  .home
    TaxonomyTopicModal(ref="taxModal")

    .row
        .col-8
            b-form(inline)
                b-form-group(label="Filter").mr-3
                    b-input(placeholder="Filter name", size="sm", v-model="selected_name", @input="get_topics(selected_name, selected_topic, selected_topic_type)")
                
                b-form-group(label="Topic type").mr-3
                    b-select(placeholder="Topic type", v-model="selected_topic_type", :options="topic_types", size="sm", @input="get_topics(selected_name, selected_topic, selected_topic_type)")
                        template(v-slot:first)
                            b-form-select-option(:value="''" ) -- All --
                            
                b-form-group(label="Children of").mr-3

                    b-select(placeholder="Children of", v-model="selected_topic", :options="topics_all", value-field="_id", text-field="name", size="sm", @input="get_topics(selected_name, selected_topic, selected_topic_type)")
                        template(v-slot:first)
                            b-form-select-option(:value="''" ) -- All --
            
              
        .col-4
            .float-right
                b-btn(@click="showModal()", size="sm", variant="outline-primary") New Taxonomy Topic
    b-row(cols="1" cols-sm="1" cols-md="2" cols-lg="2", cols-xl="3").form-row.mt-4
      b-col(v-for="topic in topics", v-if="topic").mb-2
        b-card(:id="'card-'+topic._id")
          
            h6 {{topic.name}}

            .badge.badge-secondary(v-if="topic.deleted_at") Deleted
           
            .float-right
                b-btn(@click="delete_topic(topic)", size="sm", variant="outline-secondary")#delete-btn.mt-1.mr-1 Delete
                b-btn(@click="showModal(topic)", size="sm", variant="outline-secondary")#edit-btn.mt-1 Edit
            .mt-1
                //.badge.badge-primary() Level: {{topic.level}} 
                .badge.badge-primary(v-if="topic.topic_type").mr-1 {{topic.topic_type}}
                
                .mt-1
                    span(v-if="topic.parent_ids && topic.parent_ids.length && topics")
                        .badge.badge-secondary(v-for="ide in topic.parent_ids", v-if="topics_dict[ide]").mr-1 {{topics_dict[ide].topic_type}}: {{topics_dict[ide].name}} 
                
</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'


import TaxonomyTopicModal from '@/components/TaxonomyTopicModal.vue'
import User from '@/components/User.vue'

import { mapFields } from 'vuex-map-fields';
import auth from '@/router/auth.js'

export default {
  
  name: 'home',
  
  data: function () {
    return {
        topics_all: [],
        topics: [],
        auth: auth,
        topic_types: [],
        selected_topic: "",
        topics_dict: {},
        selected_topic_type: "",
        selected_name: ""
    }
  },

  mounted: function() {
    
    function onlyUnique(value, index, self) { 
        return self.indexOf(value) === index;
    }


    Vue.http.get(`${Config.config.base_host}/topics_v1`).then((res) => {
        this.topics_all = res.body;
        this.topics = Object.assign({}, this.topics_all);
        this.topics_all.forEach((it) => {
            this.topics_dict[it._id] = it;
        });
        this.topic_types = this.topics_all.map((x) => { return x.topic_type });
        this.topic_types = this.topic_types.filter( onlyUnique );
    });

  },

  computed: {

       
        ...mapFields([
          
        ]),
  },

  methods: {
    
    get_topics(name = "", children_of = "", topic_type = "") {
        Vue.http.get(`${Config.config.base_host}/topics_v1?name=${name}&children_of=${children_of}&topic_type=${topic_type}`).then((res) => {
            this.topics = res.body;
        });
    },

    delete_topic(topic) {
         this.$bvModal.msgBoxConfirm("Are you sure? The topic will be deleted and all references to this topic will be removed as well.", {buttonSize: 'sm'}).then((value) => {
            
            if(value) {

                this.$store.dispatch("start_loading");
                  Vue.http.delete(`${Config.config.base_host}/topics_v1/${topic._id}`).then(() => {
                      this.get_topics();
                      this.$bvToast.toast(topic.name + " deleted.", {
                        title: 'Delete',
                        variant: 'primary',
                        autoHideDelay: 1000,
                      });

                
                    this.$store.dispatch("stop_loading");
                  });

            }
            
      
        });
    },

    get_name(id) {
        console.log(this.topics_all.filter((x)=>{ return x._id == id })[0].name);
        this.topics_all.filter((x)=>{ return x._id == id })[0].name;
    },

    showModal(user) {
        
        this.$refs.taxModal.open(user, this.topic_types);
    },

  },
  components: {
    User,
    TaxonomyTopicModal
 

  }
}

</script>
<style lang="scss" scoped>


</style>