<template lang="pug">
  
  .home
    RoleModal(ref="roleModal") 
    .row
      .col-12
        .float-right
          b-btn(@click="showModal()", size="sm") New role
    .row.form-row.mt-5
      .col-12(v-for="role in roles", v-if="!role.deleted_at").mb-2
        b-card(:id="'card-'+role._id")
         
            //.float-right
                b-btn(@click="showModal(role)", size="sm", variant="outline-secondary")#edit-btn.mt-1 Edit
            .row
                .col-12
                  h4 {{role.name}}
                
                  div().mt-3
                    div
                      table.table
                        tr(v-for="rg in role_config.resource_groups" v-if="permission_sets")
                          
                          td()
                            b.mr-5 {{rg}}
                         
                          td()
                            .text-muted.mt-1
                            
                              b-checkbox-group(:options="role_config.access_rights", v-model="permission_sets[role.name][rg]", @input="sync(permission_sets[role.name], role)") 
                          
                          td() 
                            b-link(@click="selectAll(role.name,rg)").mr-3 Select all
                            b-link(@click="deselectAll(role.name,rg)").ml-1 Deselect all
                
            .row
              .col-12
                .float-right
                  b-btn(@click="remove(role)", size="sm", variant="link").mr-2 Delete role
                  b-btn(@click="save(role)", size="sm") Save

</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
import RoleModal from '@/components/RoleModal.vue'
import User from '@/components/User.vue'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'home',
  
  data: function () {
    return {
        test: null,
        permission_sets: {},
        roles: [],
        role_config: {},
        search: ""
    }
  },

  mounted: function() {
      Vue.http.get(`${Config.config.base_host}/admin/role_config`).then((res) => {
          this.role_config = res.body;
          this.get_roles();
      });
      
  },

   computed: {

      
        ...mapFields([
          
        ]),
  },

  methods: {

    selectAll(name,rg) {

      this.permission_sets[name][rg] = this.role_config.access_rights;
      this.$forceUpdate();
    },

    deselectAll(name,rg) {
       this.permission_sets[name][rg] = [];
        this.$forceUpdate();
    },
    

    get_roles() {
        
        Vue.http.get(`${Config.config.base_host}/admin/roles`).then((res) => {
            this.roles = res.body;
            console.log(res.body);
            this.roles.forEach(element => {
              console.log(element);
              this.permission_sets[element.name] = {};
              this.role_config.resource_groups.forEach(it => {
                console.log(element.name, it, element.permissions);
                try {
              
                  this.permission_sets[element.name][it] = element.permissions.filter(x => x.resource_group == it)[0].access_rights;
                } catch {
                   this.permission_sets[element.name][it] = [];
                }
                
              });

            });
           console.log(this.permission_sets);
        });

       

    },


    save(role) {
        this.$store.dispatch("start_loading");
        Vue.http.put(`${Config.config.base_host}/admin/roles/${role._id}`, role.permissions).then(() => {
            this.get_roles();
            this.$bvToast.toast(role.name + " updated.", {
              title: 'Saved',
              variant: 'primary',
              autoHideDelay: 1000,
            });

       
          this.$store.dispatch("stop_loading");
        });
    },

    remove(role) {

       this.$bvModal.msgBoxConfirm("Are you sure? The role will be deleted and users might loose access", {buttonSize: 'sm'}).then((value) => {
            console.log(value);

            if(value) {

                this.$store.dispatch("start_loading");
                  Vue.http.delete(`${Config.config.base_host}/admin/roles/${role._id}`).then(() => {
                      this.get_roles();
                      this.$bvToast.toast(role.name + " updated.", {
                        title: 'Delete',
                        variant: 'primary',
                        autoHideDelay: 1000,
                      });

                
                    this.$store.dispatch("stop_loading");
                  });

            }
            
      
        });


    },

    sync(permission_set, role) {  
      role.permissions = [];
      Object.keys(permission_set).forEach((k) => {
        let rg = permission_set[k];
        role.permissions.push({"resource_group": k, "access_rights": rg});
  
      });
    },

    showModal(role) {
        
        this.$refs.roleModal.open(role);
    }

  },
  components: {
    User,
    RoleModal
  }
}

</script>
<style lang="scss" scoped>


</style>